import React, { useEffect, useRef } from "react";
import Layout from "./components/Layout";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Home from "./pages/home/Home";
import Notification from "./pages/home/Notification";
import Orders from "./pages/home/Orders";
import NotificationDetail from "./pages/home/NotificationDetail";
import Category from "./pages/home/Category";
import Profile from "./pages/home/profile";
import AboutUs from "./pages/home/AboutUs";
import Main, { HomeContent } from "./pages/main/Main";
import Events from "./pages/home/category/Events";
import Blog from "./pages/home/category/blog/Blog";
import AddBlog from "./pages/home/category/blog/AddBlog";
import InsuranceCategory from "./pages/home/category/insurance/InsuranceCategory";
import AddInsurance from "./pages/home/category/insurance/AddInsurance";
import Training from "./pages/home/category/training/Training";
import AskForTraining from "./pages/home/category/training/AskForTraining";
import AssociationInfo from "./pages/home/category/association/AssociationInfo";
import AssociationDetails from "./pages/home/category/association/AssociationDetails";
import LetsShop from "./pages/home/category/shop/LetsShop";
import UsefullLinks from "./pages/home/category/links/UsefullLinks";
import Gallery from "./pages/home/category/gallery/Gallery";
import AllPhoto from "./pages/home/category/gallery/AllPhoto";
import GetAdvice from "./pages/home/category/advice/GetAdvice";
import AddAdvice from "./pages/home/category/advice/AddAdvice";
import LegalAssistance from "./pages/home/category/assistance/LegalAssistance";
import AddLeaglAssistance from "./pages/home/category/assistance/AddLeaglAssistance";
import Mechanic from "./pages/home/category/mechanic/Mechanic";
import Messaging from "./pages/home/category/messaging/Messaging";
import store from "./redux/store";
import { get_service_list, get_window_width } from "./redux/otherSlice";
import Downloads from "./pages/home/Downloads";
import Payments from "./pages/home/Payments";
import SupplierProfile from "./pages/home/profile/SupplierProfile";
import { Terms } from "./pages/main/Terms";
import { MessagingMob } from "./pages/home/category/messaging/MessagingMob";

const App = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const userType = localStorage.getItem("type");
  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(get_service_list());
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/");
    } else {
      navigate("/");
    }
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     navigate("/");
  //   } else {
  //     navigate("/");
  //   }
  // }, [user, navigate]);

  const handleResize = () => {
    store.dispatch(get_window_width(window.innerWidth));
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Routes>
      {(userType == null || user == null) && (
        <Route path="/" element={<Main />}>
          <Route index element={<HomeContent />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
      )}
      {userType == 0 && (
        <Route element={<ProtectedRouteForMember />}>
          <Route path="/" element={<Layout />}>
            <Route element={<Outlet />}>
              <Route index element={<Home />} />
              <Route path="/category" element={<Category />} />
              <Route path="/association-info" element={<Outlet />}>
                <Route index element={<AssociationInfo />} />
                <Route
                  path="/association-info/:id"
                  element={<AssociationDetails />}
                />
              </Route>
              <Route path="/insurance" element={<Outlet />}>
                <Route index element={<InsuranceCategory />} />
                <Route
                  path="/insurance/add-insurance"
                  element={<AddInsurance />}
                />
              </Route>
              <Route path="/group-gallery" element={<Outlet />}>
                <Route index element={<Gallery />} />
                <Route
                  path="/group-gallery/all-photos"
                  element={<AllPhoto />}
                />
              </Route>
              <Route path="/let's-shop" element={<Outlet />}>
                <Route index element={<LetsShop />} />
              </Route>
              <Route path="/get-advice" element={<Outlet />}>
                <Route index element={<GetAdvice />} />
                <Route path="/get-advice/:id" element={<AddAdvice />} />
              </Route>
              <Route path="/get-legal-assistance" element={<Outlet />}>
                <Route index element={<LegalAssistance />} />
                <Route
                  path="/get-legal-assistance/:id"
                  element={<AddLeaglAssistance />}
                />
              </Route>
              <Route path="/useful-links" element={<Outlet />}>
                <Route index element={<UsefullLinks />} />
              </Route>
              <Route path="/mechanic" element={<Outlet />}>
                <Route index element={<Mechanic />} />
              </Route>
              <Route path="/training" element={<Outlet />}>
                <Route index element={<Training />} />
                <Route
                  path="/training/askfortraining"
                  element={<AskForTraining />}
                />
              </Route>
            </Route>
            <Route path="/notifications" element={<Notification />} />
            <Route path="/downloads" element={<Downloads />} />
            <Route path="/payments" element={<Payments />} />
            <Route
              path="/notification-detail"
              element={<NotificationDetail />}
            />
            <Route path="/orders" element={<Orders />} />
            <Route path="/events" element={<Events />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/add-blog" element={<AddBlog />} />
            {/* <Route path="/gallery" element={<Gallery />} /> */}
            <Route path="/messaging" element={<Messaging />} />
            <Route path="/messaging_mob" element={<MessagingMob />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/about-us" element={<AboutUs />} />
          </Route>
        </Route>
      )}
      {userType == 1 && (
        <Route element={<ProtectedRouteForSupplier />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<SupplierProfile />} />
          </Route>
        </Route>
      )}
    </Routes>
  );
};

export const ProtectedRouteForMember = () => {
  const type = localStorage.getItem("type");

  if (type == 0) {
    return <Outlet />;
  } else {
    return <Navigate to={"/"} />;
  }
};

const ProtectedRouteForSupplier = () => {
  const type = localStorage.getItem("type");

  if (type == 1) {
    return <Outlet />;
  } else {
    return <Navigate to={"/"} />;
  }
};

export default App;

// <Routes>
//   {userType == 0 ? (
//     <Route path="/" element={<Layout />}>
//       <Route element={<Outlet />}>
//         <Route index element={<Home />} />
//         <Route path="/category" element={<Category />} />
//         <Route path="/association-info" element={<Outlet />}>
//           <Route index element={<AssociationInfo />} />
//           <Route
//             path="/association-info/:id"
//             element={<AssociationDetails />}
//           />
//         </Route>
//         <Route path="/insurance" element={<Outlet />}>
//           <Route index element={<InsuranceCategory />} />
//           <Route path="/insurance/add-insurance" element={<AddInsurance />} />
//         </Route>
//         <Route path="/group-gallery" element={<Outlet />}>
//           <Route index element={<Gallery />} />
//           <Route path="/group-gallery/all-photos" element={<AllPhoto />} />
//         </Route>
//         <Route path="/let's-shop" element={<Outlet />}>
//           <Route index element={<LetsShop />} />
//         </Route>
//         <Route path="/get-advice" element={<Outlet />}>
//           <Route index element={<GetAdvice />} />
//           <Route path="/get-advice/:id" element={<AddAdvice />} />
//         </Route>
//         <Route path="/get-legal-assistance" element={<Outlet />}>
//           <Route index element={<LegalAssistance />} />
//           <Route
//             path="/get-legal-assistance/:id"
//             element={<AddLeaglAssistance />}
//           />
//         </Route>
//         <Route path="/useful-links" element={<Outlet />}>
//           <Route index element={<UsefullLinks />} />
//         </Route>
//         <Route path="/mechanic" element={<Outlet />}>
//           <Route index element={<Mechanic />} />
//         </Route>
//         <Route path="/training" element={<Outlet />}>
//           <Route index element={<Training />} />
//           <Route
//             path="/training/askfortraining"
//             element={<AskForTraining />}
//           />
//         </Route>
//       </Route>
//       <Route path="/notifications" element={<Notification />} />
//       <Route path="/downloads" element={<Downloads />} />
//       <Route path="/payments" element={<Payments />} />
//       <Route path="/notification-detail" element={<NotificationDetail />} />
//       <Route path="/orders" element={<Orders />} />
//       <Route path="/events" element={<Events />} />
//       <Route path="/blogs" element={<Blog />} />
//       <Route path="/add-blog" element={<AddBlog />} />
//       {/* <Route path="/gallery" element={<Gallery />} /> */}
//       <Route path="/messaging" element={<Messaging />} />
//       <Route path="/profile" element={<Profile />} />
//       <Route path="/about-us" element={<AboutUs />} />
//     </Route>
//   ) : userType == 1 ? (
//     <Route path="/" element={<SupplierProfile />} />
//   ) : (
//     <Route path="/" element={<Main />}>
//       <Route index element={<HomeContent />} />
//       <Route path="/login" element={<Login />} />
//       <Route path="/register" element={<SignUp />} />
//       <Route path="/reset-password" element={<ResetPassword />} />
//     </Route>
//   )}
// </Routes>;
