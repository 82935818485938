import {
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../redux/store";
import { get_events } from "../../../redux/dataSlice";
import BreadCrumName from "../../../components/BreadCrumName";
import { Loader } from "../../../utils/LayoutUtils";
import ImageModal from "../../../components/ImageModal";
import { MdOutlinePlayCircle } from "react-icons/md";

const Events = () => {
  const { state } = useLocation();
  const { events_list, events_loading } = useSelector((state) => state.allData);
  const [url, setUrl] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(get_events());
  }, []);
  const videoUrls = useMemo(() => {
    return events_list.map((item) => {
      const data = item.Url?.split(".");
      const extension = data?.[data.length - 1]?.toLowerCase();
      return extension === "mp4";
    });
  }, [events_list]);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={state?.Name}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.xl"} p={4}>
        {events_loading && events_list?.length == 0 ? (
          <Loader />
        ) : (
          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              //   "repeat(4, 1fr)",
            ]}
            gap={5}
          >
            {events_list?.map((item, index) => (
              <GridItem
                key={index}
                w="100%"
                bg="#fff"
                shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                // borderRadius={10}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
                flexDirection={"column"}
                // p={5}
                cursor={"pointer"}
                onClick={() => {
                  setUrl(item.Url);
                  onOpen();
                }}
                position={"relative"}
              >
                {videoUrls[index] && (
                  <Flex
                    position="absolute"
                    top="42%"
                    transform="translate(-50%, -50%)"
                    left="50%"
                    bg={"brand.text2"}
                    borderRadius={"50%"}
                    opacity={1}
                  >
                    <MdOutlinePlayCircle opacity={1} color="#fff" size={50} />
                  </Flex>
                )}
                <Image
                  src={item.Image}
                  h={"200"}
                  w={"100%"}
                  objectFit={"fill"}
                  alignSelf={"center"}
                />
                <Text py={3} color={"brand.text"} fontSize={16}>
                  {item.Name}
                </Text>
              </GridItem>
            ))}
          </Grid>
        )}
      </Container>
      <ImageModal url={url} isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};

export default Events;
