import {
  Box,
  Container,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import BreadCrumName from "../../../components/BreadCrumName";
import MemberProfile from "./MemberProfile";
import PartnerProfile from "./PartnerProfile";
import Payment from "./Payment";
import { CgProfile } from "react-icons/cg";
import { MdOutlinePayment } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";

const Profile = () => {
  const [c_tab, setC_tab] = useState("Member Profile");
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Profile"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Flex gap={5} mt={5} flexDirection={["column", "row", "row"]}>
        <Stack
          bg={"#fff"}
          p={5}
          maxW={["200", "200", "300"]}
          w={"100%"}
          // minH={"87vh"}
          // h={"100%"}
          position={"sticky"}
          top={0}
          display={["none", "block", "block"]}
          flex={1}
        >
          <Stack>
            <Box h={150} w={150} alignSelf={"center"}>
              <Image
                src={user.photo}
                h={"100%"}
                width={"100%"}
                objectFit={"fill"}
                borderRadius={"50%"}
              />
            </Box>
            <Text
              textAlign={"center"}
              fontSize={18}
              color={"#2b3478"}
              fontWeight={600}
            >
              {user.name}
            </Text>
            <Text
              textAlign={"center"}
              fontSize={18}
              color={"brand.text"}
              fontWeight={400}
            >
              raja@gmail.com
            </Text>
            <Divider color={"brand.text"} h={2} />
          </Stack>
          <Stack gap={3} mt={3}>
            <Text
              fontSize={16}
              color={c_tab == "Member Profile" ? "brand.white" : "brand.text"}
              fontWeight={400}
              bg={c_tab == "Member Profile" ? "#2b3478" : "brand.white"}
              px={3}
              py={1}
              borderRadius={5}
              cursor={"pointer"}
              onClick={() => setC_tab("Member Profile")}
            >
              Member Profile
            </Text>
            <Text
              fontSize={16}
              color={c_tab == "Partner Profile" ? "brand.white" : "brand.text"}
              fontWeight={400}
              bg={c_tab == "Partner Profile" ? "#2b3478" : "brand.white"}
              px={3}
              py={1}
              borderRadius={5}
              cursor={"pointer"}
              onClick={() => setC_tab("Partner Profile")}
            >
              Partner Profile
            </Text>
            <Text
              fontSize={16}
              color={c_tab == "Payment" ? "brand.white" : "brand.text"}
              fontWeight={400}
              bg={c_tab == "Payment" ? "#2b3478" : "brand.white"}
              px={3}
              py={1}
              borderRadius={5}
              cursor={"pointer"}
              onClick={() => setC_tab("Payment")}
            >
              Payment
            </Text>
          </Stack>
        </Stack>
        <Stack
          flexDirection={"row"}
          gap={[10,3]}
          mt={3}
          pt={[3,0]}
          pb={[3,0]}
          pl={[5,0]}
          pr={[5,0]}
          position={"relative"}
          bg={"#fff"}
          justifyContent={["space-between","center"]}
          display={["flex", "none", "none"]}
        >
          <Text
            fontSize={25}
            color={c_tab == "Member Profile" ? "brand.white" : "brand.text"}
            fontWeight={400}
            bg={c_tab == "Member Profile" ? "#2b3478" : "brand.white"}
            px={3}
            py={1}
            borderRadius={5}
            cursor={"pointer"}
            onClick={() => setC_tab("Member Profile")}
          >
            <CgProfile />
          </Text>
          <Text
            fontSize={25}
            color={c_tab == "Partner Profile" ? "brand.white" : "brand.text"}
            fontWeight={400}
            bg={c_tab == "Partner Profile" ? "#2b3478" : "brand.white"}
            px={3}
            py={1}
            borderRadius={5}
            cursor={"pointer"}
            onClick={() => setC_tab("Partner Profile")}
          >
            <FaHandshake />
          </Text>
          <Text
            fontSize={25}
            color={c_tab == "Payment" ? "brand.white" : "brand.text"}
            fontWeight={400}
            bg={c_tab == "Payment" ? "#2b3478" : "brand.white"}
            px={3}
            py={1}
            borderRadius={5}
            cursor={"pointer"}
            onClick={() => setC_tab("Payment")}
          >
            <MdOutlinePayment />
          </Text>
        </Stack>
        <Stack bg={"#fff"} p={5} flex={1}>
          {c_tab == "Member Profile" && <MemberProfile title={c_tab} />}
          {c_tab == "Partner Profile" && <PartnerProfile title={c_tab} />}
          {c_tab == "Payment" && <Payment title={c_tab} />}
        </Stack>
      </Flex>
    </Container>
  );
};

export default Profile;
