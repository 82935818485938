import { Flex, GridItem, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";

export const DotHeading = ({ title }) => {
  return (
    <Text
      display={"flex"}
      flexDirection={"row"}
      gap={2}
      alignItems={"center"}
      fontSize={["14", "16", "18", "20"]}
      color={"#5B686A"}
      fontWeight={600}
      mt={2}
    >
      <GoDotFill />
      <Text>{title}</Text>
    </Text>
  );
};

export const HeadingMain = ({ title, ref }) => {
  return (
    <Text
      fontSize={["21", "24", "27", "30"]}
      fontWeight={600}
      textAlign={"center"}
      ref={ref}
      pt={[2, 3, 4, 5]}
      pb={[2, 3, 4, 5]}
      color={"black"}
    >
      {title}
    </Text>
  );
};

export const HeadingSub = ({ title }) => {
  return (
    <Text
      fontSize={["18", "20", "22", "24"]}
      color={"black"}
      fontWeight={200}
      mt={2}
    >
      {title}
    </Text>
  );
};

export const HeadingText = ({ title, pl }) => {
  return (
    <Text
      fontSize={["14", "16", "18", "20"]}
      color={"#5B686A"}
      textAlign={"justify"}
      fontWeight={400}
      paddingLeft={pl}
    >
      {title}
    </Text>
  );
};

export const MailLink = ({
  senderEmail,
  recipientEmail,
  subject,
  body,
  title,
}) => {
  const handleClick = () => {
    const mailtoLink = `mailto:${recipientEmail}?cc=${senderEmail}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink, "_blank");
  };

  return <Link onClick={handleClick}>{title}</Link>;
};

export const HeaderItems = ({ title, onClick }) => {
  const [sameData, setSameData] = useState("");
  const hover = {
    width: "fix-content",
    fontWeight: "bold",
    textDecoration: "underline",
    transition: "all .5s ease-in",
  };
  return (
    <div>
      <Text
        cursor={"pointer"}
        fontSize={"large"}
        fontWeight={400}
        color={"#fff"}
        onMouseEnter={() => {
          setSameData(title);
        }}
        onMouseLeave={() => {
          setSameData("");
        }}
        onClick={onClick}
        _hover={hover}
      >
        {title}
      </Text>
      {title == sameData && <div className="header_hover"></div>}
    </div>
  );
};

export const DrawerItem = ({ title, onClick }) => {
  return (
    <Text
      cursor={"pointer"}
      fontSize={"large"}
      fontWeight={400}
      color={"#2b3478"}
      onClick={onClick}
      p={2}
      _hover={{
        fontWeight: "800",
      }}
    >
      {title}
    </Text>
  );
};

export const GridInputComponent = ({ content, mt = 0, p = [1, 5] }) => {
  return (
    <GridItem
      mt={mt}
      mb={mt - 1}
      w="100%"
      bg="#fff"
      borderRadius={10}
      alignItems={"center"}
      justifyContent={"flex-start"}
      display={"flex"}
      flexDirection={"row"}
      p={p}
      cursor={"pointer"}
      gap={2}
    >
      {content}
    </GridItem>
  );
};

export const ListDataShow = ({ title, value }) => {
  return (
    <Flex px={3} mt={1} mb={1} w={"100%"}>
      <Text flex={3} color={"brand.text"} fontSize={16}>
        {title}
      </Text>
      <Text flex={12} color={"brand.text2"} fontSize={15}>
        : {value}
      </Text>
    </Flex>
  );
};

export const ShareContent = ({ url }) => {
  return <img src={url} alt="Certificate" />;
};
