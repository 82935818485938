import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const get_policy_company_list = createAsyncThunk(
  "company_list",
  async () => {
    const body = new FormData();
    body.append("action", "list");
    const responce = await fetch(
      "https://insugo.vensframe.com/api/provider.php",
      {
        method: "POST",
        body: body,
      }
    );
    const res = await responce.json();
    return res;
  }
);
export const get_district_list = createAsyncThunk("district", async () => {
  const responce = await fetch(process.env.REACT_APP_BASE_URL + "distric.php", {
    method: "POST",
  });
  const res = await responce.json();
  return res;
});

export const get_service_list = createAsyncThunk("service", async () => {
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "services.php",
    { method: "GET" }
  );
  const res = await responce.json();
  return res;
});
export const get_other_policy_list = createAsyncThunk("other", async () => {
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "Insurance_list.php",
    { method: "POST" }
  );
  const res = await responce.json();
  return res;
});
export const get_window_width = createAsyncThunk("width", async () => {
  return window.innerWidth;
});

const initialState = {
  policy_company_list: [],
  policy_company_loading: false,
  district_list: [],
  district_loading: false,
  services_list: [],
  services_loading: false,
  other_policy_list: [],
  other_policy_loading: false,
  win_width: "",
};
const otherSlice = createSlice({
  name: "other",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(get_policy_company_list.pending, (state, action) => {
      state.policy_company_loading = true;
    });
    builder.addCase(get_policy_company_list.rejected, (state, action) => {
      state.policy_company_loading = false;
    });
    builder.addCase(get_policy_company_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.policy_company_loading = false;
      if (data.success) {
        state.policy_company_list = data.message;
      } else {
        state.policy_company_list = [];
      }
    });

    builder.addCase(get_district_list.pending, (state, action) => {
      state.district_loading = true;
    });
    builder.addCase(get_district_list.rejected, (state, action) => {
      state.district_loading = false;
    });
    builder.addCase(get_district_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.district_loading = false;
      if (data.status == 200) {
        state.district_list = data.record;
      } else {
        state.district_list = [];
      }
    });

    builder.addCase(get_service_list.pending, (state, action) => {
      state.services_loading = true;
    });
    builder.addCase(get_service_list.rejected, (state, action) => {
      state.services_loading = false;
    });
    builder.addCase(get_service_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.services_loading = false;
      if (data.status) {
        state.services_list = data.msg;
      } else {
        state.services_list = [];
      }
    });

    builder.addCase(get_other_policy_list.pending, (state, action) => {
      state.other_policy_loading = true;
    });
    builder.addCase(get_other_policy_list.rejected, (state, action) => {
      state.other_policy_loading = false;
    });
    builder.addCase(get_other_policy_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.other_policy_loading = false;
      if (data.success) {
        state.other_policy_list = data.list;
      } else {
        state.other_policy_list = [];
      }
    });
    builder.addCase(get_window_width.fulfilled, (state, action) => {
      const data = action.payload;
      state.win_width = data;
    });
  },
});

export const { reset } = otherSlice.actions;
export default otherSlice.reducer;
