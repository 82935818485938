import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CustomButton,
  InputBox,
  Loader,
  SelectImage,
} from "../../../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import { toast } from "react-toastify";
import { FaStar } from "react-icons/fa";
import CustomModal from "../../../../components/CustomModal";
import { GridInputComponent } from "../../../../components/Compnent";
import { DateValidator2 } from "../../../../utils/utils";
import moment from "moment/moment";

const Mechanic = () => {
  const { state } = useLocation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const [data, setData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  const today = moment().subtract(0, "days").format("DD/MM/YYYY");

  const get_list = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "list");
      body.append("district", user.district_id);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Mechanic.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setList(res.list);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    get_list();
  }, []);

  const validate = async () => {
    const obj = {};
    // const data2 = await DateValidator2(
    //   moment(data?.fdate).format("DD/MM/YYYY"),
    //   today,
    //   day_end_date,
    //   `Invoice Date cant be below  as Day end Date ${day_end_date}`,
    //   "Invoice Date cant be a future date"
    // );
    // else if (data != true) {
    //   arr.push(...data);
    // }
    if (!data?.fdate || (data?.fdate && data?.fdate?.length != 10)) {
      obj.fdateMess = "Please Select Start date";
    }
    // else if (data2 != true) {
    //   arr.push(...data2);
    // }

    if (!data?.tdate || (data?.tdate && data?.tdate?.length != 10)) {
      obj.tdateMess = "Please Select End date";
    }

    if (
      !data?.address ||
      (data?.address && data?.address?.trim()?.length == 0)
    ) {
      obj.addressMess = "Please Enter Address";
    } else if (data?.address?.trim()?.length < 8) {
      obj.addressMess = "Address Should be min 8 charactors";
    }
    if (!data?.status || (data?.status && data?.status?.length == 0)) {
      obj.statusMess = "Please Select Status Type";
    }
    if (
      !data?.certificate?.name ||
      (data?.certificate?.name && data?.certificate?.name?.length == 0)
    ) {
      obj.certificateMess = "Please Select Certificate";
    }
    if (
      !data?.aadhar?.name ||
      (data?.aadhar?.name && data?.aadhar?.name?.length == 0)
    ) {
      obj.aadharMess = "Please Select Aadhar";
    }
    setData({
      ...data,
      ...obj,
    });
  };

  const on_assign = async () => {
    const data1 = validate();
    if (
      data?.tdate &&
      data?.tdate?.length == 10 &&
      data?.fdate &&
      data?.fdate?.length == 10 &&
      data?.address &&
      data?.address?.trim()?.length >= 8 &&
      data?.status &&
      data?.status?.length > 0 &&
      data?.certificate?.name &&
      data?.certificate?.name?.length > 0 &&
      data?.aadhar?.name &&
      data?.aadhar?.name?.length > 0
    ) {
      try {
        const body = new FormData();
        body.append("action", "create");
        body.append("category_id", data?.category_id); 
        body.append("userid", user?.id);
        body.append("from_Date", data?.fdate); 
        body.append("to_date", data?.tdate); 
        body.append("list", data?.list_id); 
        body.append("address", data?.address); 
        body.append("status", data?.status); 
        body.append("certificate", data?.certificate); 
        body.append("aadhar_upload", data?.aadhar); 
        body.append("district", data.district); 
        const responce = await fetch(
          process.env.REACT_APP_BASE_URL + "Mechanic.php",
          { method: "POST", body: body }
        );
        const res = await responce.json();
        setLoading(false);
        if (res.success) {
          setData({});
          toast.success(res.message);
          onClose();
          get_list();
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Mechanic/MI"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.sm"} p={4}>
        {loading ? (
          <Loader />
        ) : (
          <Flex flexWrap={"wrap"} gap={5} justifyContent={"center"}>
            {list?.map((item, index) => (
              <Flex
                key={index}
                w={"full"}
                bg="#fff"
                shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                borderRadius={10}
                p={5}
                gap={5}
              >
                <Image
                  src={item.Photo}
                  h={"100%"}
                  w={"70px"}
                  maxH={"70px"}
                  objectFit={"cover"}
                  borderRadius={"50%"}
                />
                <Stack flex={1}>
                  <Text color={"brand.text"} fontSize={18} fontWeight={600}>
                    {item.Name}
                  </Text>
                  <Flex gap={2}>
                    <FaStar
                      color={item?.Rating > 0 ? "#FFD700" : "gray"}
                      size={20}
                    />
                    <FaStar
                      color={item?.Rating > 1 ? "#FFD700" : "gray"}
                      size={20}
                    />
                    <FaStar
                      color={item?.Rating > 2 ? "#FFD700" : "gray"}
                      size={20}
                    />
                    <FaStar
                      color={item?.Rating > 3 ? "#FFD700" : "gray"}
                      size={20}
                    />
                    <FaStar
                      color={item?.Rating > 4 ? "#FFD700" : "gray"}
                      size={20}
                    />
                  </Flex>
                </Stack>

                <CustomButton
                  title={"Assign"}
                  onClick={() => {
                    setName(item.Name);
                    setData({
                      ...data,
                      category_id: item.Category_id,
                      district: item?.District,
                      list_id: item?.ID,
                    });
                    onOpen();
                  }}
                />
              </Flex>
            ))}
          </Flex>
        )}
      </Container>
      <CustomModal
        onCancel={() => {
          setData({});
        }}
        isOpen={isOpen}
        onClose={onClose}
        title={`${name}`}
      >
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
          ]}
          gap={[2, 4, 4, 4]}
        >
          <GridInputComponent
            content={
              <InputBox
                label={"From Date"}
                placeholder={"From Date"}
                type={"date"}
                value={data.fdate}
                onChange={(e) => {
                  setData({ ...data, fdate: e.target.value, fdateMess: "" });
                }}
                errorMess={data?.fdateMess}
              />
            }
          />
          <GridInputComponent
            content={
              <InputBox
                label={"To Date"}
                placeholder={"To Date"}
                type={"date"}
                value={data.tdate}
                onChange={(e) => {
                  if (e.target.value?.length <= 10) {
                    setData({ ...data, tdate: e.target.value, tdateMess: "" });
                  }
                }}
                errorMess={data?.tdateMess}
              />
            }
          />
          <GridInputComponent
            content={
              <InputBox
                label={"Address"}
                placeholder={"Address"}
                value={data.address}
                onChange={(e) => {
                  setData({
                    ...data,
                    address: e.target.value,
                    addressMess: "",
                  });
                }}
                errorMess={data?.addressMess}
              />
            }
          />
          <GridInputComponent
            content={
              <RadioGroup
                value={data.status}
                onChange={(e) => {
                  setData({ ...data, status: e, statusMess: "" });
                }}
                errorMess={data?.statusMess}
              >
                <Stack spacing={5} direction="row">
                  <Flex color={"brand.primary"}> Status</Flex>
                  <Radio colorScheme="purple" value="1">
                    Yes
                  </Radio>
                  <Radio colorScheme="purple" value="2">
                    No
                  </Radio>
                </Stack>
                {data?.statusMess && (
                  <Text mt={2} color={"#f00"} fontSize={14}>
                    {data?.statusMess}
                  </Text>
                )}
              </RadioGroup>
            }
          />
          <GridInputComponent
            content={
              <SelectImage
                label={"Certificates"}
                filename={data?.certificate?.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    certificate: e.target.files[0],
                    certificateMess: "",
                  });
                }}
                errorMess={data?.certificateMess}
              />
            }
          />
          <GridInputComponent
            content={
              <SelectImage
                label={"Upload Aadhar"}
                filename={data?.aadhar?.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    aadhar: e.target.files[0],
                    aadharMess: "",
                  });
                }}
                errorMess={data?.aadharMess}
              />
            }
          />
        </Grid>
        <Center mt={5}>
          <CustomButton
            title={"Submit"}
            onClick={on_assign}
            loading={loading}
          />
        </Center>
      </CustomModal>
    </Container>
  );
};

export default Mechanic;
