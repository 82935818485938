import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { get_profile } from "../../../redux/dataSlice";
import store from "../../../redux/store";
import { Center, Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { CustomButton, InputBox, Loader } from "../../../utils/LayoutUtils";
import ShowData from "../../../components/ShowData";
import CustomModal from "../../../components/CustomModal";
import { IoMdDownload } from "react-icons/io";

const PartnerProfile = ({ title }) => {
  const { profile_data, profile_loading } = useSelector(
    (state) => state.allData
  );
  const { win_width } = useSelector((state) => state.otherData);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const body = new FormData();
    body.append("Mobile_Number", "8500118510");
    body.append("type", 3);
    store.dispatch(get_profile(body));
  }, []);
  const data = profile_data[0];
  return (
    <>
      <Flex gap={5} alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={18} color={"#2b3478"} fontWeight={600}>
          {title}
        </Text>
        <CustomButton title={"Edit"} onClick={onOpen} />
      </Flex>
      {profile_loading ? (
        <Loader />
      ) : (
        <Stack>
          <Flex
            gap={5}
            mt={2}
            flexDirection={["column", "column", "row", "row"]}
          >
            <ShowData title={"Agency ID"} value={data?.Agency_id} flex={1} />
            <ShowData title={"Name"} value={data?.Name} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"DOB"} value={data?.DOB} flex={1} />
            <ShowData title={"DOA"} value={data?.DOA} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Mobile Number"}
              value={data?.Mobile_Number}
              flex={1}
            />
            <ShowData
              title={"Partner Status"}
              value={data?.Partner_Status}
              flex={1}
            />
          </Flex>

          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"Address"} value={data?.Address} flex={1} />
            <ShowData
              title={"Email Address"}
              value={data?.Email_add}
              flex={1}
            />
          </Flex>

          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"DOJ"} value={data?.DOJ} flex={1} />
            <ShowData title={"DOE"} value={data?.DOE} flex={1} />
          </Flex>
          <Flex
            gap={5}
            flexDirection={["row", "row", "row"]}
            alignItems={"center"}
            mt={5}
          >
            <Text>Identity Card</Text>
            <CustomButton
              title={
                win_width < 500 ? (
                  <IoMdDownload color="#fff" size={22} cursor={"pointer"} />
                ) : (
                  "Download"
                )
              }
            />
          </Flex>
        </Stack>
      )}
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Update Partner Profile"}
      >
        <Flex gap={[0,5]} flexDirection={["column", "column", "row", "row"]}>
          <InputBox placeholder={"Agency Name"} label={"Agency Name"} />
          <InputBox placeholder={"Email Address"} label={"Email Address"} />
        </Flex>
        <Flex gap={[0,5]} flexDirection={["column", "column", "row", "row"]}>
          <InputBox placeholder={"Address"} label={"Address"} />
          <Flex flex={1} />
        </Flex>

        <Center mt={5} mb={5}>
          <CustomButton title={"Update"} />
        </Center>
      </CustomModal>
    </>
  );
};

export default PartnerProfile;
