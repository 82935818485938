import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const get_our_team = createAsyncThunk("our_team", async () => {
  const body = new FormData();
  body.append("type", "Our_team");
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "Web_Page.php",
    { method: "POST", body: body }
  );
  const res = await responce.json();
  return res;
});

export const get_slider_data = createAsyncThunk("slider_data", async () => {
  const body = new FormData();
  body.append("type", "slider_images");
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "Web_Page.php",
    { method: "POST", body: body }
  );
  const res = await responce.json();
  return res;
});
export const get_hiring = createAsyncThunk("hiring", async () => {
  const body = new FormData();
  body.append("type", "hiring");
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "Web_Page.php",
    { method: "POST", body: body }
  );
  const res = await responce.json();
  return res;
});

export const get_initiatives_data = createAsyncThunk(
  "initiatives_data",
  async () => {
    const body = new FormData();
    body.append("type", "initiatives");
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Initiatives.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);
export const get_initiatives_img = createAsyncThunk(
  "initiatives_img",
  async () => {
    const body = new FormData();
    body.append("type", "images");
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Initiatives.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);
export const get_join_us = createAsyncThunk("join_us", async () => {
  const body = new FormData();
  body.append("type", "join_with_us");
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "Web_Page.php",
    { method: "POST", body: body }
  );
  const res = await responce.json();
  return res;
});

export const get_gallery_img = createAsyncThunk("gallery_img", async () => {
  const body = new FormData();
  body.append("type", "Gallery");
  const responce = await fetch(
    process.env.REACT_APP_BASE_URL + "Web_Page.php",
    { method: "POST", body: body }
  );
  const res = await responce.json();
  return res;
});
export const get_refund_policies = createAsyncThunk(
  "efund_policies",
  async () => {
    // const body = new FormData();
    // body.append("type", "Refund_Policy");
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Refund_policy.php",
      { method: "POST" }
    );
    const res = await responce.json();
    return res;
  }
);
export const get_privacy_policies = createAsyncThunk(
  "privacy_policies",
  async () => {
    // const body = new FormData();
    // body.append("type", "Privacy_Policy");
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Privacy_policy.php",
      { method: "POST", }
    );
    const res = await responce.json();
    return res;
  }
);
export const get_terms_and_conditions = createAsyncThunk(
  "terms_and_conditions",
  async () => {
    const body = new FormData();
    body.append("type", "Terms_condition");
    const responce = await fetch(
      process.env.REACT_APP_BASE_URL + "Web_Page.php",
      { method: "POST", body: body }
    );
    const res = await responce.json();
    return res;
  }
);
export const get_about_us_home = createAsyncThunk("about_us", async () => {
  const responce = await fetch(process.env.REACT_APP_BASE_URL + "about.php", {
    method: "POST",
  });
  const res = await responce.json();
  return res;
});

const initialState = {
  our_team: [],
  about_us: "",
  slider_data: [],
  hiring: [],
  initiatives_data: [],
  initiatives_img: [],
  join_with_us: [],
  gallery_img: [],
  refund_policies: [],
  privacy_policies: [],
  terms_and_conditions: [],
  gallery_img: [],
  home_loading: false,
};
const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(get_our_team.fulfilled, (state, action) => {
      const data = action.payload;
      state.home_loading = false;
      if (data.success) {
        state.our_team = data.message;
      } else {
        state.our_team = [];
      }
    });
    builder.addCase(get_about_us_home.fulfilled, (state, action) => {
      const data = action.payload;
      console.log("data asaad========",data);
      state.home_loading = false;
      if (data.success) {
        state.about_us = data.message;
      } else {
        state.about_us = "";
      }
    });
    builder.addCase(get_slider_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.home_loading = false;
      if (data.success) {
        state.slider_data = data.message;
      } else {
        state.slider_data = [];
      }
    });
    builder.addCase(get_hiring.fulfilled, (state, action) => {
      const data = action.payload;
      state.home_loading = false;
      if (data.success) {
        state.hiring = data.message;
      } else {
        state.hiring = [];
      }
    });
    builder.addCase(get_initiatives_data.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.initiatives_data = data.list;
      } else {
        state.initiatives_data = [];
      }
    });
    builder.addCase(get_initiatives_img.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.initiatives_img = data.list;
      } else {
        state.initiatives_img = [];
      }
    });

    builder.addCase(get_join_us.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.join_with_us = data.message;
      } else {
        state.join_with_us = [];
      }
    });

    builder.addCase(get_gallery_img.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.gallery_img = data.message;
      } else {
        state.gallery_img = [];
      }
    });

    builder.addCase(get_refund_policies.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.refund_policies = data.data;
      } else {
        state.refund_policies = [];
      }
    });

    builder.addCase(get_privacy_policies.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.privacy_policies = data.data;
      } else {
        state.privacy_policies = [];
      }
    });

    builder.addCase(get_terms_and_conditions.fulfilled, (state, action) => {
      const data = action.payload;
      if (data.success) {
        state.terms_and_conditions = data.message;
      } else {
        state.terms_and_conditions = [];
      }
    });
  },
});

export const { reset } = homeSlice.actions;
export default homeSlice.reducer;
