import {
  Box,
  Checkbox,
  Container,
  Flex,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  InputBox,
  SelectBox,
  SelectImage,
} from "../../utils/LayoutUtils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { onLogin, onRegister, onRegister1 } from "../../utils/apis";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { get_district_list, get_service_list } from "../../redux/otherSlice";
import Select from "react-select";
import { validateEmail } from "../../utils/utils";

const SignUp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState({
    mobile: "",
    address: "",
  });
  const [loading, setLoading] = useState(false);
  const [selcted, setSelcted] = useState("1");
  const [selectService, setSelectService] = useState([]);
  const [checked, setChecked] = useState(false);
  const { district_list, services_list } = useSelector(
    (state) => state.otherData
  );

  useEffect(() => {
    // setData({ ...data, mobile: state });
    store.dispatch(get_service_list());
    store.dispatch(get_district_list());
  }, []);

  const onClickSignUp = () => {
    if (
      !data.a_name ||
      !data.a_id ||
      !data.omc ||
      !data.proprietor ||
      !data.proprietor_name ||
      !data.dist ||
      !data.email ||
      !data.mobile ||
      (data.mobile && data.mobile?.length < 9) ||
      !validateEmail(data.email)
    ) {
      setData({
        ...data,
        a_nameMess: !data.a_name ? "Agency Name is required" : "",
        a_idMess: !data.a_id ? "Agency Code is required" : "",
        omcMess: !data.omc ? "OMC Type is required" : "",
        proprietorMess: !data.proprietor ? "Proprietor is required" : "",
        proprietor_nameMess: !data.proprietor_name
          ? "Proprietor Name is required"
          : "",
        distMess: !data.dist ? "District is required" : "",
        emailMess: !data.email
          ? "Email is required"
          : !validateEmail(data.email)
          ? "Invailid email address"
          : "",
        mobileMess: !data.mobile
          ? "Mobile Number is required"
          : data.mobile && data.mobile?.length < 9
          ? "Invailid Mobile Number"
          : "",
      });
      return;
    }
    const body = new FormData();
    body.append("type", selcted == 1 ? 0 : 1);
    body.append("agency_name", data.a_name);
    body.append("agency_code", data.a_id);
    body.append("omc_type", data.omc);
    body.append("pp", data.proprietor);
    body.append("state", "Telangana");
    body.append("district", data.dist);
    body.append("email", data.email);
    body.append("mobile_number", data.mobile);
    body.append("pname", data.proprietor_name);
    onRegister(body, setLoading)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          navigate("/login");
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => console.log(error));
  };

  const onClickSignUp1 = () => {
    if (
      !data.full_name ||
      !data.dist ||
      !data.email ||
      !data.address ||
      selectService.length == 0 ||
      !data.mobile ||
      (data.mobile && data.mobile?.length < 9) ||
      !validateEmail(data.email)
    ) {
      setData({
        ...data,
        full_nameMess: !data.full_name ? "Full Name is required" : "",
        distMess: !data.dist ? "District is required" : "",
        emailMess: !data.email
          ? "Email is required"
          : !validateEmail(data.email)
          ? "Invailid Email address"
          : "",
        addressMess: !data.address ? "Address is required" : "",
        serviceMess: selectService.length == 0 ? "Services are required" : "",
        mobileMess: !data.mobile
          ? "Mobile Number is required"
          : data.mobile && data.mobile?.length < 9
          ? "Invailid Mobile Number"
          : "",
      });
      return;
    }
    const body = new FormData();
    body.append("type", 1);
    body.append("service", selectService.map((v) => v.label).toString());
    body.append("name", data.full_name);
    body.append("email", data.email);
    body.append("address", data.address);
    body.append("state", "Telangana");
    body.append("district", data.dist);
    body.append("email", data.email);
    body.append("mobile_number", data.mobile);
    onRegister1(body, setLoading)
      .then((res) => {
        if (res.success) {
          toast.success(res.message);
          navigate("/login");
        } else {
          toast.error(res.msg);
        }
      })
      .catch((error) => console.log(error));
  };
  const optionslist = [];
  for (let i = 0; i < services_list?.length; i++) {
    const element = {
      value: services_list[i]?.services_name,
      label: services_list[i]?.services_name,
    };
    optionslist.push(element);
  }

  return (
    <Container
      alignItems={"center"}
      justifyContent={"flex-start"}
      minW="100vw"
      display={"flex"}
      flexDirection={"column"}
      p={0}
    >
      <Container maxW={"container.lg"} pt={5}>
        <Flex alignSelf={"flex-start"} alignItems={"center"} gap={5}>
          <IoArrowBackOutline
            size={30}
            color="#2b3478"
            cursor={"pointer"}
            onClick={() => navigate(-1)}
          />
          <Text fontSize={[18, 22, 26, 30]} fontWeight={600} color={"#2b3478"}>
            Register
          </Text>
        </Flex>
      </Container>
      <Stack p={5} maxW="lg" w={"100%"} mt={["10px", 10, 20, 70]}>
        <RadioGroup value={selcted} onChange={setSelcted}>
          <Stack spacing={5} direction={["column", "row"]}>
            <Radio colorScheme="blue" opacity={"1"} value="1">
              Become a Member
            </Radio>
            <Radio colorScheme="blue" opacity={"1"} value="2">
              Become service provider
            </Radio>
          </Stack>
        </RadioGroup>
        {selcted == 1 ? (
          <>
            <InputBox
              placeholder={"Agency Name"}
              mt={2}
              value={data.a_name}
              onChange={(v) => {
                setData({
                  ...data,
                  a_name: v.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " "),
                  a_nameMess: "",
                });
              }}
              errorMess={data?.a_nameMess}
            />
            <InputBox
              placeholder={"Agency code"}
              value={data.a_id}
              onChange={(v) => {
                setData({
                  ...data,
                  a_id: v.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/\s{2,}/g, " "),
                  a_idMess: "",
                });
              }}
              errorMess={data?.a_idMess}
            />
            <SelectBox
              placeholder={"OMC Type"}
              value={data.omc}
              onChange={(v) => {
                setData({ ...data, omc: v.target.value, omcMess: "" });
              }}
              errorMess={data?.omcMess}
              options={
                <>
                  <option value="1">HPCL</option>
                  <option value="2">BPCL</option>
                  <option value="3">IOCL</option>
                </>
              }
            />
            <SelectBox
              placeholder={"Proprietor"}
              value={data.proprietor}
              onChange={(v) => {
                setData({
                  ...data,
                  proprietor: v.target.value,
                  proprietorMess: "",
                });
              }}
              errorMess={data?.proprietorMess}
              options={
                <>
                  <option value="Proprietory type">Proprietory type</option>
                  <option value="Partnership type">Partnership type</option>
                </>
              }
            />
            <InputBox
              placeholder={"Proprietor Name"}
              value={data.proprietor_name}
              onChange={(v) => {
                setData({
                  ...data,
                  proprietor_name: v.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " "),
                  proprietor_nameMess: "",
                });
              }}
              errorMess={data?.proprietor_nameMess}
            />
            <InputBox placeholder={"State"} value={"Telangana"} />
            <SelectBox
              placeholder={"District"}
              value={data.dist}
              onChange={(v) => {
                setData({ ...data, dist: v.target.value, distMess: "" });
              }}
              errorMess={data?.distMess}
              options={district_list?.map((item, index) => (
                <option key={index} value={item.district_names}>
                  {item.district_names}
                </option>
              ))}
            />
            <InputBox
              placeholder={"Email Id"}
              value={data.email}
              onChange={(v) => {
                setData({
                  ...data,
                  email: v.target.value.trim(),
                  emailMess: "",
                });
              }}
              errorMess={data?.emailMess}
            />

            <InputBox
              placeholder={"Mobile Number"}
              value={data.mobile}
              maxLength={10}
              onChange={(v) => {
                setData({
                  ...data,
                  mobile: v.target.value.trim().replace(/[^0-9 ]/g, ""),
                  mobileMess: "",
                });
              }}
              errorMess={data?.mobileMess}
            />
            {/* <SelectImage
              filename={data?.img?.name}
              doctype={".png,.jpg,.jpeg"}
              onChange={(v) => {
                setData({ ...data, img: v.target.files[0], imgMess: "" });
              }}
              errorMess={data?.imgMess}
            /> */}
          </>
        ) : (
          <>
            <Spacer h={1} />
            <Select
              fontSize={"14px"}
              isMulti
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: state.isFocused ? "#2b3478" : "#88888833",
                  "&:hover": {
                    // border: 1,
                    borderColor: state.isFocused ? "#2b3478" : "#88888855",
                  },
                }),
              }}
              placeholder={"Select Services"}
              value={selectService}
              onChange={(v) => {
                setSelectService(v);
                setData({ ...data, serviceMess: "" });
              }}
              options={optionslist}
              // style={{h}}
            />
            {data.serviceMess && (
              <Text color={"#f00"} fontSize={14}>
                {data.serviceMess}
              </Text>
            )}
            <InputBox
              placeholder={"Full Name"}
              mt={2}
              value={data.full_name}
              onChange={(v) => {
                setData({
                  ...data,
                  full_name: v.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " "),
                  full_nameMess: "",
                });
              }}
              errorMess={data?.full_nameMess}
            />
            <InputBox
              placeholder={"Email Id"}
              value={data.email}
              onChange={(v) => {
                setData({
                  ...data,
                  email: v.target.value.trim(),
                  emailMess: "",
                });
              }}
              errorMess={data?.emailMess}
            />
            <InputBox
              placeholder={"Mobile Number"}
              value={data.mobile}
              maxLength={10}
              onChange={(v) => {
                setData({
                  ...data,
                  mobile: v.target.value.trim().replace(/[^0-9 ]/g, ""),
                  mobileMess: "",
                });
              }}
              errorMess={data?.mobileMess}
            />
            <InputBox
              placeholder={"Address"}
              value={data.address}
              onChange={(v) => {
                setData({
                  ...data,
                  address: v.target.value.trimStart().replace(/\s{2,}/g, " "),
                  // .replace(/[^a-zA-Z0-9 ]/g, "")
                  // .replace(/^[0-9]/, "")
                  // .replace(/\s{2,}/g, " "),
                  addressMess: "",
                });
              }}
              errorMess={data?.addressMess}
            />
            <InputBox placeholder={"State"} value={"Telangana"} />
            <SelectBox
              placeholder={"Select District"}
              value={data.dist}
              onChange={(v) => {
                setData({
                  ...data,
                  dist: v.target.value
                    .trimStart()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/^[0-9]/, "")
                    .replace(/\s{2,}/g, " "),
                  distMess: "",
                });
              }}
              errorMess={data?.distMess}
              options={district_list?.map((item, index) => (
                <option value={item.district_names} key={index}>
                  {item.district_names}
                </option>
              ))}
            />
          </>
        )}
        <Text
          fontSize={14}
          fontWeight={500}
          color={"brand.primary"}
          display={"flex"}
          gap={"1rem"}
          justifyContent={"flex-start"}
          alignItems={["flex-start", "center"]}
          mt={2}
        >
          <Checkbox
            borderColor={"gray"}
            mt={[1, 0]}
            isChecked={checked}
            defaultChecked={checked}
            onChange={(e) => {
              setChecked(!checked);
            }}
            colorScheme={"blue"}
          />
          <Text>
            By clicking Submit, you agree to our{" "}
            <Link
              to="/terms"
              textDecoration={"underline"}
              style={{ textDecoration: "underline" }}
            >
              Terms & Conditions
            </Link>
          </Text>
        </Text>

        <CustomButton
          title={"Submit"}
          mt={5}
          disabled={!checked}
          loading={loading}
          onClick={() => {
            selcted == 1 ? onClickSignUp() : onClickSignUp1();
          }}
        />
      </Stack>
    </Container>
  );
};

export default SignUp;
