import {
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_blogs, get_events } from "../../../../redux/dataSlice";
import BreadCrumName from "../../../../components/BreadCrumName";
import { CustomButton, Loader } from "../../../../utils/LayoutUtils";
import ImageModal from "../../../../components/ImageModal";
import { ListDataShow } from "../../../../components/Compnent";

const Blog = () => {
  const { state } = useLocation();
  const { blogs_list, blogs_loading } = useSelector((state) => state.allData);
  const [url, setUrl] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  useEffect(() => {
    const body = new FormData();
    body.append("action", "list");
    store.dispatch(get_blogs(body));
  }, []);
  console.log("blogs_list =====", blogs_list);
  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex justifyContent={"space-between"}>
        <BreadCrumName
          current={"Blogs"}
          titlelist={[{ name: "Home", to: "/" }]}
        />
        <CustomButton
          title={"Add Blog"}
          onClick={() => navigate("/add-blog")}
        />
      </Flex>
      <Container maxW={"container.xl"} p={4}>
        {blogs_loading && blogs_list?.length == 0 ? (
          <Loader />
        ) : (
          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={5}
          >
            {blogs_list?.map((item, index) => (
              <GridItem
                key={index}
                w="100%"
                bg="#fff"
                shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                display={"flex"}
                flexDirection={"column"}
                cursor={"pointer"}
                onClick={() => {
                  setUrl(item.Url);
                  onOpen();
                }}
              >
                <Image
                  src={item.Url}
                  h={"200"}
                  w={"100%"}
                  objectFit={"fill"}
                  //   p={5}
                  alignSelf={"center"}
                  //   borderRadius={10}
                />
                <ListDataShow title={"Name"} value={item.Name}/>
                <ListDataShow title={"Title"} value={item.Title}/>
                <ListDataShow title={"Messages"} value={item.Messages}/>
                <ListDataShow title={"Reason"} value={item.Reason}/>
                {/* <Text px={3} mt={2} color={"brand.text"} fontSize={16}>
                  Name: {item.Name}
                </Text>
                <Text px={3} mb={2} color={"brand.text"} fontSize={16}>
                  Title: {item.Title}
                </Text>
                <Text px={3} mb={2} color={"brand.text"} fontSize={16}>
                  Messages: {item.Messages}
                </Text>
                <Text px={3} mb={2} color={"brand.text"} fontSize={16}>
                  Reason: {item.Reason}
                </Text> */}
              </GridItem>
            ))}
          </Grid>
        )}
      </Container>
      <ImageModal url={url} isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};

export default Blog;
