import {
  Container,
  Grid,
  GridItem,
  Heading,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../../utils/LayoutUtils";
import store from "../../redux/store";
import { get_home_data, get_notification_list } from "../../redux/dataSlice";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { home_data, home_loading } = useSelector((state) => state.allData);
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  useEffect(() => {
    store.dispatch(get_home_data());
  }, []);

  return (
    <Container maxW={"container"} py={0} px={0}>
      <Container maxW={"container"} p={5}>
        <Heading color={"#2b3478"} fontSize={[21, 24, 27, 30]} fontWeight={800}>
          Hi {user?.name}
        </Heading>
        <Heading color={"brand.text2"} fontSize={[18, 21, 23, 25]} mt={[2, 4]}>
          Welcome to Telangana LPG Distributors Association
        </Heading>
        <Spacer h={[4, 6, 8, 10]} />
        <Container maxW={"container.xl"} p={[1, 2, 3, 4]}>
          {home_loading ? (
            <Loader />
          ) : (
            <Grid
              templateColumns={[
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)",
              ]}
              gap={[5, 5, 10]}
            >
              {home_data?.map((item, index) => (
                <GridItem
                  key={index}
                  w="100%"
                  bg="#fff"
                  shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                  borderRadius={10}
                  alignItems={"center"}
                  justifyContent={"center"}
                  display={"flex"}
                  flexDirection={"column"}
                  cursor={"pointer"}
                  p={2}
                  onClick={() => {
                    item.ID == 1
                      ? navigate("/association-info", { state: item })
                      : item.ID == 2
                      ? navigate("/useful-links", { state: item })
                      : item.ID == 3
                      ? navigate("/group-gallery", { state: item })
                      : item.ID == 4
                      ? navigate("/get-advice", { state: item })
                      : item.ID == 5
                      ? navigate("/get-legal-assistance", { state: item })
                      : item.ID == 6
                      ? navigate("/mechanic", { state: item })
                      : item.ID == 7
                      ? navigate("/let's-shop", { state: item })
                      : item.ID == 8
                      ? navigate("/insurance", { state: item })
                      : item.ID == 9
                      ? navigate("/training", { state: item })
                      : item.ID == 10
                      ? navigate("/messaging", { state: item })
                      : item.ID == 11
                      ? navigate("/events", { state: item })
                      : item.ID == 12
                      ? navigate("/blogs", { state: item })
                      : navigate("/category", { state: item });
                  }}
                >
                  <Image
                    src={item.Imageurl_web}
                    h={["100%", "70%"]}
                    w={["100%", "70%"]}
                    objectFit={"fill"}
                    p={5}
                    alignSelf={"center"}
                  />
                  <Text
                    color={"#000"}
                    fontSize={18}
                    fontWeight={400}
                    mb={3}
                    textAlign={"center"}
                  >
                    {item.Name}
                  </Text>
                </GridItem>
              ))}
            </Grid>
          )}
        </Container>
      </Container>
    </Container>
  );
};

export default Home;
