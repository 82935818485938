import {
  Box,
  Center,
  Container,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BreadCrumName from "../../../../components/BreadCrumName";
import {
  CustomButton,
  InputBox,
  Loader,
  SelectBox,
  SelectImage,
} from "../../../../utils/LayoutUtils";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_blogs } from "../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { get_district_list } from "../../../../redux/otherSlice";
import moment from "moment";
import { DateValidator2 } from "../../../../utils/utils";
import CustomModal from "../../../../components/CustomModal";
import { IoMdAdd } from "react-icons/io";
const today = moment().format("DD/MM/YYYY");
const afterOneYearDate = moment().add(1, "years").format("DD/MM/YYYY");

const AskForTraining = () => {
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const mobile = localStorage.getItem("mobile");
  const { district_list } = useSelector((state) => state.otherData);
  // const [loading, setLoading] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataList, setDataList] = useState([]);

  console.log("dataList =========", dataList);

  const onAddTraining = async () => {
    try {
      const validatefdate = DateValidator2(
        moment(data?.fdate).format("DD/MM/YYYY"),
        afterOneYearDate,
        today,
        `From Date cant be before ${moment().format("DD-MMM-YYYY")}`,
        `From Date cant be after ${moment(afterOneYearDate).format(
          "DD-MMM-YYYY"
        )}`
      );
      const validatetodate = DateValidator2(
        moment(data?.todate).format("DD/MM/YYYY"),
        afterOneYearDate,
        moment(data?.fdate).format("DD/MM/YYYY"),
        `To Date cant be before ${moment(data?.fdate).format("DD-MMM-YYYY")}`,
        `To Date cant be after ${moment(afterOneYearDate).format(
          "DD-MMM-YYYY"
        )}`
      );
      if (
        !data.dist ||
        !data.gender ||
        !data.members ||
        !data.fdate ||
        validatefdate !== true ||
        !data.todate ||
        validatefdate !== true
      ) {
        setData({
          ...data,
          distMess: !data.dist ? "District is required" : "",
          genderMess: !data.gender ? "Gender is required" : "",
          membersMess: !data.members ? "Members are required" : "",
          fdateMess: !data.fdate
            ? "From Date is required"
            : validatefdate !== true
            ? `${validatefdate[0]}`
            : "",
          todateMess: !data.todate
            ? "To Date is required"
            : validatetodate !== true
            ? `${validatetodate[0]}`
            : "",
        });
        return;
      }
      setLoading(true);
      const body = new FormData();
      body.append("action", "create");
      body.append("gender", data.gender);
      body.append("req_member", data.members);
      body.append("from_date", data.fdate);
      body.append("to_date", data.todate);
      body.append("category_id", state.cat_id);
      body.append("sub_category_id", state.subCat_id);
      body.append("userid", user.id);
      body.append("location", data.dist);
      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Training.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        get_list();
        onClose();
      } else {
        toast.error(res.message);
      }
    } catch (error) {}
  };
  const get_list = async () => {
    try {
      setLoading(true);
      const body = new FormData();
      body.append("action", "list");
      body.append("category_id", state.cat_id);
      body.append("sub_category_id", state.subCat_id);
      body.append("userid", user.id);

      const responce = await fetch(
        process.env.REACT_APP_BASE_URL + "Training.php",
        { method: "POST", body: body }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setDataList(res.data);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    get_list();
  }, []);
  useEffect(() => {
    store.dispatch(get_district_list());
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <Flex
        gap={[1, 5]}
        justifyContent={"space-between"}
        flexDirection={["column", "row"]}
      >
        <BreadCrumName
          current={state.name}
          titlelist={[
            { name: "Home", to: "/" },
            { name: "Training & Development", to: "/training" },
          ]}
        />
        <Flex w={["100%", "auto"]} justifyContent={"flex-end"}>
          <CustomButton
            icon={<IoMdAdd size={20} color="#fff" />}
            title={"Add"}
            onClick={onOpen}
          />
        </Flex>
      </Flex>

      <Container maxW={"container.sm"} p={4}>
        {loading ? (
          <Loader />
        ) : dataList.length == 0 ? (
          <Center p={10}>
            <Text fontSize={20} color={"brand.text"} fontWeight={"600"}>
              No Data Found
            </Text>
          </Center>
        ) : (
          dataList.map((item, index) => (
            <Box
              key={index}
              bg={"#fff"}
              p={5}
              mt={2}
              cursor={"pointer"}
              borderRadius={10}
              gap={"5%"}
            >
              <Text fontSize={16} color={"brand.text"} fontWeight={"600"}>
                Gender : {item.Gender}
              </Text>
              <Text fontSize={16} color={"brand.black"} fontWeight={"400"}>
                Location : {item.Location}
              </Text>
              <Text fontSize={16} color={"brand.black"} fontWeight={"400"}>
                Required Members : {item.Req_member}
              </Text>
              <Text fontSize={16} color={"brand.black"} fontWeight={"400"}>
                From Date : {moment(item.From_date)?.format("DD-MMM-YYYY")}
              </Text>
              <Text fontSize={16} color={"brand.black"} fontWeight={"400"}>
                To Date : {moment(item.To_date)?.format("DD-MMM-YYYY")}
              </Text>
            </Box>
          ))
        )}
      </Container>

      <CustomModal
        onCancel={() => setData({})}
        isOpen={isOpen}
        onClose={onClose}
        title={`Add ${state.name}`}
      >
        <Container maxW={"container.sm"} p={4}>
          <Text color={"brand.text"} fontSize={16} mb={1}>
            Gender
          </Text>
          <RadioGroup
            //   defaultValue=""
            value={data.gender}
            onChange={(e) => {
              setData({ ...data, gender: e, genderMess: "" });
            }}
          >
            <Stack spacing={5} direction="row">
              <Radio colorScheme="purple" value="Male">
                Male
              </Radio>
              <Radio colorScheme="purple" value="Female">
                Female
              </Radio>
            </Stack>
          </RadioGroup>
          {data?.genderMess && (
            <Text color={"#f00"} fontSize={14}>
              {data?.genderMess}
            </Text>
          )}
          <SelectBox
            label={"Select Loaction"}
            placeholder={"Select Loaction"}
            value={data.dist}
            onChange={(v) => {
              setData({ ...data, dist: v.target.value, distMess: "" });
            }}
            errorMess={data.distMess}
            options={district_list?.map((item, index) => (
              <option key={index}>{item.district_names}</option>
            ))}
          />
          <InputBox
            label={"Training required for how many members"}
            placeholder={"Training required for how many members"}
            value={data.members}
            onChange={(v) => {
              setData({
                ...data,
                members: v.target.value.trim().replace(/[^0-9 ]/g, ""),
                membersMess: "",
              });
            }}
            errorMess={data.membersMess}
          />

          <InputBox
            label={"From Date"}
            placeholder={"From Date"}
            type={"date"}
            value={data.fdate}
            onChange={(v) => {
              setData({ ...data, fdate: v.target.value, fdateMess: "" });
            }}
            errorMess={data.fdateMess}
          />
          <InputBox
            label={"To Date"}
            placeholder={"To Date"}
            type={"date"}
            value={data.todate}
            onChange={(v) => {
              setData({ ...data, todate: v.target.value, todateMess: "" });
            }}
            errorMess={data.todateMess}
          />

          <Center mt={6}>
            <CustomButton
              title={"Submit"}
              onClick={() => onAddTraining()}
              loading={loading}
            />
          </Center>
        </Container>
      </CustomModal>
    </Container>
  );
};

export default AskForTraining;
