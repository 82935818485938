import {
  Box,
  Container,
  Flex,
  Grid,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../../../redux/store";
import { get_category } from "../../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { Loader } from "../../../../utils/LayoutUtils";
import BreadCrumName from "../../../../components/BreadCrumName";
import { get_policy_company_list } from "../../../../redux/otherSlice";
import CustomPopup from "../../../../components/CustomPopup";
import ListItem from "../../../../components/ListItem";

const LetsShop = () => {
  const { state } = useLocation();
  const { categoty_data, cat_loading } = useSelector((state) => state.allData);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    const body = new FormData();
    body.append("action", "Details");
    body.append("id", 7);
    store.dispatch(get_category(body));
    store.dispatch(get_policy_company_list());
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={"Let's Shop"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.xl"} mt={[5,5]} p={[1, 2, 3, 4]}>
        {cat_loading && categoty_data?.length == 0 ? (
          <Loader />
        ) : categoty_data?.length == 0 ? (
          <Text>Working</Text>
        ) : (
          <Grid
          templateColumns={[
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(4, 1fr)",
          ]}
          gap={[5, 5, 10]}
        >
            {categoty_data?.map((item, index) => (
              <ListItem
                key={index}
                title={item.name}
                url={item.Web_Images}
                onClick={() => onOpen()}
              />
              //   <Box
              //     w={300}
              //     bg="#fff"
              //     shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
              //     borderRadius={10}
              //     alignItems={"center"}
              //     justifyContent={"center"}
              //     display={"flex"}
              //     flexDirection={"column"}
              //     p={5}
              //     cursor={"pointer"}
              //     onClick={() => onOpen()}
              //   >
              //     <Image
              //       src={item.Url}
              //       h={200}
              //       w={200}
              //       objectFit={"contain"}
              //       p={5}
              //     />
              //     <Text
              //       textAlign={"center"}
              //       color={"#000"}
              //       fontSize={18}
              //       fontWeight={400}
              //     >
              //       {item.name}
              //     </Text>
              //   </Box>
            ))}
          </Grid>
        )}
      </Container>  
      <CustomPopup
      fontSize={40}
        isOpen={isOpen}
        onClose={onClose}
        // title={"Oops"}
        mess={"Coming soon..."}
        single_button={true}
        b_color={"#2b3478"}
        b_name={"ok"}
      />
    </Container>
  );
};

export default LetsShop;
