import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
  Input,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { images } from "../utils/utils";
import { FaCircleUser } from "react-icons/fa6";
import { IoNotificationsOutline, IoMenu } from "react-icons/io5";
import { LuDownload } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton } from "../utils/LayoutUtils";
import CustomPopup from "./CustomPopup";
import { DrawerItem, HeaderItems } from "./Compnent";
import { get_notification_list } from "../redux/dataSlice";
import store from "../redux/store";
import { useSelector } from "react-redux";

const Header = ({ paragraphRef }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPopOpen,
    onOpen: onPopOpen,
    onClose: onPopClose,
  } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { notification_count } = useSelector((state) => state.allData);
  useEffect(() => {
    store.dispatch(get_notification_list());
  }, []);

  return (
    <Flex
      w="100%"
      maxW="1400px"
      alignSelf={"center"}
      px={5}
      alignItems={"center"}
      gap={10}
      justifyContent={"space-between"}
    >
      <Image
        src={images.logo}
        h={["50px", "60px"]}
        w={["50px", "60px"]}
        objectFit={"contain"}
        cursor={"pointer"}
        borderRadius={6}
        bg={"brand.white"}
        p={1}
        onClick={() => navigate("/")}
      />

      <Flex gap={5} alignItems={"center"} display={["none", "flex", "flex"]}>
        {user == null ? (
          <MainMenu />
        ) : (
          <HomeMenu
            count={notification_count}
            user={user}
            onClick={() => onPopOpen()}
          />
        )}
      </Flex>
      <Flex gap={5} alignItems={"center"} display={["flex", "none", "none"]}>
        {/* <IoNotificationsOutline
          color="#fff"
          size={25}
          cursor={"pointer"}
          onClick={() => navigate("/notifications")}
        /> */}
        <div style={{ position: "relative" }}>
          {notification_count && notification_count > 0 && (
            <div
              style={{
                position: "absolute",
                color: "white",
                backgroundColor: "rgb(209, 38, 43)",
                borderRadius: "50%",
                height: "18px",
                width: "18px",
                top: "-7px",
                right: "-7px",
                fontSize: "12px",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {notification_count}
            </div>
          )}

          <IoNotificationsOutline
            color="#fff"
            size={25}
            cursor={"pointer"}
            onClick={() => navigate("/notifications")}
          />
        </div>
        <IoMenu
          color="#fff"
          size={25}
          cursor={"pointer"}
          onClick={() => onOpen()}
        />
      </Flex>
      <Drawer
        w={"80vw"}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image
              src={images.logo}
              h={["50px", "60px"]}
              w={["50px", "60px"]}
              objectFit={"contain"}
              cursor={"pointer"}
              borderRadius={6}
              bg={"brand.white"}
              p={1}
              onClick={() => {
                navigate("/");
                onClose();
              }}
            />
          </DrawerHeader>

          <DrawerBody>
            <DrawerMenu
              onClose={onClose}
              user={user}
              colorblack={true}
              onClick={() => onPopOpen()}
              count={notification_count}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <CustomPopup
        isOpen={isPopOpen}
        onClick={() => {
          localStorage.removeItem("user");
          localStorage.removeItem("type");
          window.location.reload();
          navigate("/");
          onPopClose();
        }}
        b_name={"Log Out"}
        onClose={onPopClose}
        title={"Log Out"}
        mess={"Are you sure? You want to logout."}
      />
    </Flex>
  );
};

const HomeMenu = ({ colorblack, user, onClick, count }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userType = localStorage.getItem("type");
  const handleMouseEnter = (event) => {
    event.target.style.fontWeight = "bold";
  };

  const handleMouseLeave = (event) => {
    event.target.style.fontWeight = "normal";
  };
  return (
    <>
      {userType == 1 || (
        <LuDownload
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          color="#fff"
          size={25}
          cursor={"pointer"}
          onClick={() => navigate("/downloads")}
        />
      )}
      {userType == 1 || (
        <div style={{ position: "relative" }}>
          {count && count > 0 && (
            <div
              style={{
                position: "absolute",
                color: "white",
                backgroundColor: "rgb(209, 38, 43)",
                borderRadius: "50%",
                height: "18px",
                width: "18px",
                top: "-7px",
                right: "-7px",
                fontSize: "12px",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {count}
            </div>
          )}

          <IoNotificationsOutline
            color="#fff"
            size={25}
            cursor={"pointer"}
            onClick={() => navigate("/notifications")}
          />
        </div>
      )}
      {userType == 1 || (
        <HeaderItems title={"Orders"} onClick={() => navigate("/orders")} />
      )}
      {userType == 1 || (
        <HeaderItems title={"Payments"} onClick={() => navigate("/payments")} />
      )}
      <HeaderItems title={"Log Out"} onClick={onClick} />
      {userType == 1 ||
        (user == null ? (
          <FaCircleUser
            color="#fff"
            size={30}
            cursor={"pointer"}
            onClick={() => navigate("/profile")}
          />
        ) : (
          <Box
            h={9}
            w={9}
            alignSelf={"center"}
            onClick={() => navigate("/profile")}
            cursor={"pointer"}
          >
            <Image
              src={user.photo}
              h={"100%"}
              width={"100%"}
              objectFit={"fill"}
              borderRadius={"50%"}
            />
          </Box>
        ))}
    </>
  );
};
const DrawerMenu = ({
  colorblack,
  user,
  showprofileText,
  onClick,
  onClose,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const data = [
    {
      title: "",
      url: "/",
    },
  ];

  return (
    <>
      <DrawerItem
        title={"Profile"}
        onClick={() => {
          navigate("/profile");
          if (onClose) {
            onClose();
          }
        }}
      />
      <DrawerItem
        title={"Home"}
        onClick={() => {
          navigate("/");
          if (onClose) {
            onClose();
          }
        }}
      />
      <DrawerItem
        title={"Orders"}
        onClick={() => {
          navigate("/orders");
          if (onClose) {
            onClose();
          }
        }}
      />
      <DrawerItem
        title={"Payments"}
        onClick={() => {
          navigate("/payments");
          if (onClose) {
            onClose();
          }
        }}
      />
      <DrawerItem
        title={"Downloads"}
        onClick={() => {
          navigate("/downloads");
          if (onClose) {
            onClose();
          }
        }}
      />
      <DrawerItem title={"Log Out"} onClick={onClick} />
    </>
  );
};
const MainMenu = ({ colorblack }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <CustomButton
        title={"New User"}
        bg={"#d1262b"}
        onClick={() => navigate("/register")}
      />
      <CustomButton
        title={"Login"}
        bg={"#d1262b"}
        onClick={() => navigate("/login")}
      />
    </>
  );
};

export default Header;
