import { extendTheme } from "@chakra-ui/react";

export const images = {
  logo: require("../assets/lpg-logo.jpeg"),
  googleplay: require("../assets/googleplay.png"),
  appstore: require("../assets/appstore.png"),
};

const customColors = {
  primary: "#2b3478",
  brand: {
    primary: "#2b3478",
    black: "#000000",
    btn: "#d1262b",
    white: "#fff",
    text: "#2b3478",
    text1: "#A8B1B3",
    text2: "#000000",
  },
};
export const theme = extendTheme({
  fonts: {
    heading: `'Lato', sans-serif`,
    body: `'Lato', sans-serif`,
  },
  colors: { ...customColors },
});

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const categorylist = [
  {
    ID: "1",
    Name: "Association Info",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/leadership.svg",
  },
  {
    ID: "2",
    Name: "Useful Links",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/downloads.svg",
  },
  {
    ID: "3",
    Name: "Gallery",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/gallery.svg",
  },
  {
    ID: "4",
    Name: "Get Advice",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/advisory.svg",
  },
  {
    ID: "5",
    Name: "Get Legal Assistance",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/legalassistance.svg",
  },
  {
    ID: "6",
    Name: "Mechanic/MI",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/mechanic.svg",
  },
  {
    ID: "7",
    Name: "Let's Shop",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/consumables.svg",
  },
  {
    ID: "8",
    Name: "Buy Insurance",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/insurance.svg",
  },
  {
    ID: "9",
    Name: "Ask for Training",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/training.svg",
  },
  {
    ID: "10",
    Name: "Messaging",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/messaging.svg",
  },
  {
    ID: "11",
    Name: "Event Landscape ",
    imageUrl:
      "https://mylpg.pmpframe.com/association/category_icons/eventlandscape.svg",
  },
  {
    ID: "12",
    Name: "Blogs",
    imageUrl: "https://mylpg.pmpframe.com/association/category_icons/blogs.svg",
  },
];

export const DateValidator2 = (
  dateValue,
  endDate = "",
  validateDate = "",
  mess1 = "",
  mess2 = ""
) => {
  const [dayA, monthA, yearA] = dateValue.split("/").map(Number);
  const [dayB, monthB, yearB] = validateDate.split("/").map(Number);
  const [dayC, monthC, yearC] = endDate.split("/").map(Number);
  const dateA = new Date(yearA, monthA - 1, dayA);
  const dateB = new Date(yearB, monthB - 1, dayB);
  const dateC = new Date(yearC, monthC - 1, dayC);
  const errors = [];
  if (dateA < dateB) {
    errors.push(mess1);
  } else if (dateA > dateC) {
    errors.push(mess2);
  }

  if (errors.length > 0) {
    return errors;
  } else {
    return true;
  }
};
