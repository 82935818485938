import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Center,
  Container,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ShowData from "../../../components/ShowData";
import store from "../../../redux/store";
import { get_profile } from "../../../redux/dataSlice";
import { useSelector } from "react-redux";
import { CustomButton, InputBox, Loader } from "../../../utils/LayoutUtils";
import CustomModal from "../../../components/CustomModal";
import { IoMdDownload } from "react-icons/io";
import { IoMdShare } from "react-icons/io";
import { certificate_download_api } from "../../../utils/apis";
import { toast } from "react-toastify";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { ShareContent } from "../../../components/Compnent";

const MemberProfile = ({ title }) => {
  const { profile_data, profile_loading } = useSelector(
    (state) => state.allData
  );
  const cancelRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const [url, seturl] = useState("");
  const mobile = localStorage.getItem("mobile");
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  useEffect(() => {
    const body = new FormData();
    body.append("Mobile_Number", mobile);
    body.append("type", 1);
    store.dispatch(get_profile(body));
  }, []);

  const data = profile_data?.length == 0 ? {} : profile_data[0];

  const { win_width } = useSelector((state) => state.otherData);

  const Download_file = async (type) => {
    try {
      const body = new FormData();
      body.append("ID", user?.id);
      const res = await certificate_download_api(body);
      if (res?.success) {
        if (type == "download") {
          const link = document.createElement("a");
          link.target = "_blank";
          link.href = res.url;
          link.download = "filename";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (type == "share") {
          seturl(res.url);
        }

        // const response = await fetch(res?.url, {
        //   method: "GET",
        // });

        // if (!response.ok) {
        //   throw new Error(`HTTP error! status: ${response.status}`);
        // }

        // // Convert the response to a Blob
        // const blob = await response.blob();

        // // Create a link element, set its href to the object URL, and trigger the download
        // const downloadLink = document.createElement("a");
        // downloadLink.href = window.URL.createObjectURL(blob);
        // downloadLink.download = "filename.jpg"; // Set the desired file name
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);

        // // Clean up the object URL
        // window.URL.revokeObjectURL(res?.url);
      } else if (res?.success) {
        toast.error(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Flex gap={5} alignItems={"center"} justifyContent={"space-between"}>
        <Text fontSize={18} color={"#2b3478"} fontWeight={600}>
          {title}
        </Text>
        <CustomButton title={"Edit"} onClick={onOpen} />
      </Flex>
      {profile_loading ? (
        <Loader />
      ) : (
        <Stack>
          <Flex
            gap={5}
            mt={2}
            flexDirection={["column", "column", "row", "row"]}
          >
            <ShowData title={"Agency ID"} value={data?.Agency_ID} flex={1} />
            <ShowData
              title={"Agency Name"}
              value={data?.Agency_name}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"DOC"} value={data?.DOC} flex={1} />
            <ShowData title={"OMC Type"} value={data?.OMC_Type} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Agency Consistution"}
              value={data?.Agency_Const}
              flex={1}
            />
            <ShowData
              title={"Agency Category"}
              value={data?.Agency_cate}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"Agency MKT"} value={data?.Agency_MKT} flex={1} />
            <ShowData
              title={"Agency Reconsitution"}
              value={data?.Agency_recon}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"Address"} value={data?.Address} flex={1} />
            <ShowData title={"District"} value={data?.district} flex={1} />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData title={"State"} value={data?.State} flex={1} />
            <ShowData
              title={"Mobile Number"}
              value={data?.Mobile_Number}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Email Address"}
              value={data?.Email_Address}
              flex={1}
            />
            <ShowData
              title={"Date of Membership"}
              value={data?.Date_of_Membership}
              flex={1}
            />
          </Flex>
          <Flex gap={5} flexDirection={["column", "column", "row", "row"]}>
            <ShowData
              title={"Distributor Type"}
              value={data?.Distributor_Type}
              flex={1}
            />
            <ShowData title={"Date Time"} value={data?.DataTime} flex={1} />
          </Flex>
          <Flex gap={5} alignItems={"center"} mt={5}>
            <Text>Registration Certificate</Text>
            <CustomButton
              onClick={() => Download_file("download")}
              title={
                win_width < 500 ? (
                  <IoMdDownload color="#fff" size={22} cursor={"pointer"} />
                ) : (
                  "Download"
                )
              }
            />
            <CustomButton
              onClick={() => {
                Download_file("share");
                onOpen1();
              }}
              title={
                win_width < 500 ? (
                  <IoMdShare color="#fff" size={22} cursor={"pointer"} />
                ) : (
                  "Share"
                )
              }
            />
          </Flex>
        </Stack>
      )}

      <AlertDialog
        isOpen={isOpen1}
        leastDestructiveRef={cancelRef}
        onClose={onClose1}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Share App
            </AlertDialogHeader>
            <AlertDialogBody>
              <Center gap={5} mb={3}>
                <WhatsappShareButton
                  title={"My LPG Association"}
                  separator=":: "
                  url={url}
                >
                  <WhatsappIcon round={true} size={40} />
                </WhatsappShareButton>
                <TelegramShareButton
                  title={"My LPG Association"}
                  separator=":: "
                  url={url}
                >
                  <TelegramIcon round={true} size={40} />
                </TelegramShareButton>
                <EmailShareButton subject={"My LPG Association"} url={url}>
                  <EmailIcon round={true} size={40} />
                </EmailShareButton>
              </Center>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Update Member Profile"}
      >
        <Flex
          gap={[0, 0, 5]}
          flexDirection={["column", "column", "row", "row"]}
        >
          <InputBox placeholder={"Agency Name"} label={"Agency Name"} />
          <InputBox placeholder={"DOC"} label={"DOC"} />
        </Flex>
        <Flex
          gap={[0, 0, 5]}
          flexDirection={["column", "column", "row", "row"]}
        >
          <InputBox placeholder={"OMC Type"} label={"OMC Type"} />
          <InputBox placeholder={"Address"} label={"Address"} />
        </Flex>

        <Flex
          gap={[0, 0, 5]}
          flexDirection={["column", "column", "row", "row"]}
        >
          <InputBox placeholder={"Email Address"} label={"Email Address"} />
          <Flex flex={1} />
        </Flex>
        <Center mt={5} mb={5}>
          <CustomButton title={"Update"} />
        </Center>
      </CustomModal>
    </>
  );
};

export default MemberProfile;
