import { Container, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../redux/store";
import { get_category } from "../../redux/dataSlice";
import { useSelector } from "react-redux";
import { Loader } from "../../utils/LayoutUtils";

const Category = () => {
  const { state } = useLocation();
  const { categoty_data, cat_loading } = useSelector((state) => state.allData);
  const navigate = useNavigate();
  useEffect(() => {
    const body = new FormData();
    body.append("action", "Details");
    body.append("id", state.ID);
    store.dispatch(get_category(body));
  }, []);

  return (
    <Container maxW={"container.xl"} py={5}>
      <BreadCrumName
        current={state.Name}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.xl"} p={4}>
        {cat_loading && categoty_data?.length == 0 ? (
          <Loader />
        ) : categoty_data?.length == 0 ? (
          <Text>Working</Text>
        ) : (
          <Grid
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
              "repeat(5, 1fr)",
            ]}
            gap={10}
          >
            {categoty_data?.map((item, index) => (
              <GridItem
                key={index}
                w="100%"
                bg="#fff"
                shadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                borderRadius={10}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
                flexDirection={"column"}
                p={5}
                cursor={"pointer"}
              >
                <Image
                  src={item.Url}
                  h={"90%"}
                  w={"90%"}
                  objectFit={"contain"}
                  p={5}
                  alignSelf={"center"}
                />
                <Text
                  textAlign={"center"}
                  color={"#000"}
                  fontSize={18}
                  fontWeight={400}
                >
                  {item.name}
                </Text>
              </GridItem>
            ))}
          </Grid>
        )}
      </Container>
    </Container>
  );
};

export default Category;
