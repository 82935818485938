import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";

const InputBox = ({
  mt,
  placeholder,
  label,
  value,
  onChange,
  errorMess,
  type,
  RightElement,
  maxLength,
  area,
  defaultValue,
  max,
  disable,
}) => {
  return (
    <Stack flex={1} mt={mt || 2} spacing={0}>
      {label && (
        <Text color={"brand.text"} fontSize={16} mb={1}>
          {label}
        </Text>
      )}
      <InputGroup size="md">
        {area ? (
          <Textarea
            placeholder={placeholder}
            bg={"#fff"}
            fontSize={14}
            fontWeight={400}
            color={"#000"}
            size={"md"}
            focusBorderColor={"#2b3478"}
            value={value}
            onChange={onChange}
            type={type}
            maxLength={maxLength}
            resize={"vertical"}
            disabled={disable}
          />
        ) : (
          <Input
            placeholder={placeholder}
            bg={"#fff"}
            fontSize={14}
            fontWeight={400}
            color={"#000"}
            size={"md"}
            focusBorderColor={"#2b3478"}
            value={value}
            onChange={onChange}
            type={type}
            maxLength={maxLength}
            max={max}
            defaultValue={defaultValue}
            disabled={disable}
          />
        )}
        {RightElement && (
          <InputRightElement width="3rem" cursor={"pointer"}>
            {RightElement}
            {/* <Button h="1.75rem" size="sm" onClick={handleClick}>
            {show ? "Hide" : "Show"}
          </Button> */}
          </InputRightElement>
        )}
      </InputGroup>
      {errorMess && (
        <Text color={"#f00"} fontSize={14} mt={1}>
          {errorMess}
        </Text>
      )}
    </Stack>
  );
};
const SelectBox = ({
  label,
  mt,
  placeholder,
  options,
  value,
  onChange,
  errorMess,
  multiple,
  defaultValue,
}) => {
  return (
    <Stack flex={1} mt={mt || 2}>
      {label && (
        <Text color={"brand.text"} fontSize={16} mb={1}>
          {label}
        </Text>
      )}
      <Select
        multiple={multiple}
        placeholder={placeholder}
        fontSize={14}
        fontWeight={400}
        color={"#000"}
        size={"md"}
        focusBorderColor={"#2b3478"}
        value={value}
        onChange={onChange}
        bg={"#fff"}
        defaultValue={defaultValue}
      >
        {options}
      </Select>
      {errorMess && (
        <Text color={"#f00"} fontSize={14}>
          {errorMess}
        </Text>
      )}
    </Stack>
  );
};

const SelectImage = ({
  onChange,
  filename,
  mt,
  inputRef,
  doctype,
  multiple = false,
  onBlur,
  errorMess,
  label,
}) => {
  const inputOpenFileRef = React.createRef();
  const showOpenFileDlg = () => {
    inputOpenFileRef.current.click();
  };
  return (
    <FormControl flex={1} mt={mt || 2}>
      {label && (
        <Text color={"brand.text"} fontSize={16} mb={1}>
          {label}
        </Text>
      )}
      <HStack
        ref={inputRef}
        borderWidth={1}
        borderStyle={"solid"}
        borderColor={"gray.200"}
        borderRadius={5}
        h={"10"}
        alignItems={"center"}
        onClick={showOpenFileDlg}
        overflow={"hidden"}
        bg={"#fff"}
      >
        <Text flex={1} px={2}>
          {filename || "Choose file"}
        </Text>
        <Text
          color={"#fff"}
          h={10}
          m={0}
          paddingInline={8}
          textAlign={"center"}
          alignItems={"center"}
          pt={2}
          fontSize={"lg"}
          fontWeight={"bolder"}
          bg={"brand.btn"}
        >
          Browse
        </Text>
        <Input
          ref={inputOpenFileRef}
          type="file"
          multiple={multiple}
          onChange={onChange}
          display={"none"}
          accept={doctype || "image/png, image/jpg, image/jpeg ,.pdf"}
          onBlur={onBlur}
        />
      </HStack>
      {errorMess && (
        <Text color={"#f00"} fontSize={14}>
          {errorMess}
        </Text>
      )}
    </FormControl>
  );
};

const CustomButton = ({
  title,
  loading,
  onClick,
  mt,
  bg,
  h,
  icon,
  id,
  t_color,
  disabled = false,
  bstart,
  hover = true,
}) => {
  return (
    <Button
      id={id}
      variant={"solid"}
      h={h || ["8", 30]}
      mt={mt}
      bg={disabled ? "brand.text1" : bg || "brand.btn"}
      borderWidth={1}
      _hover={
        disabled
          ? ""
          : {
              // fontSize:"2px",
              // padding: "1rem",
              width: "auto",
              fontWeight: "bolder",
              transition: "all .2s ease-in",
            }
      }
      color={t_color || "#fff"}
      isLoading={loading}
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      alignSelf={!bstart ? "center" : "flex-start"}
      gap={2}
      fontSize={["sm", "sm", "md"]}
      disabled={disabled}
      fontWeight={"400"}
      border={"none"}
    >
      {icon}
      {title}
    </Button>
  );
};

const Loader = () => {
  return (
    <Center py={"10"} bg={"gray.100"} mt={4} borderRadius={7}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#2b3478"
        size="xl"
      />
    </Center>
  );
};

export { InputBox, CustomButton, SelectBox, SelectImage, Loader };
